<template>
    <ul class="item_list">
        <li>
          <router-link tabindex="1" :to="showAbout()" class="link">
            {{ $t("About Us") }}
          </router-link>
          <!-- <a href="#" rel="kee">About Us</a> -->
        </li>
        <li>
          <router-link tabindex="1" :to="showTermsOfUse()" class="link">
            {{ $t("Terms of Use") }}
          </router-link>
          <!-- <a href="#" rel="kee">Terms of Use</a> -->
        </li>
        <li>
          <router-link tabindex="1" :to="showPrivacyPolicy()" class="link">
            {{ $t("Privacy Policy") }}
          </router-link>
          <!-- <a href="#" rel="kee">Privacy Policy</a> -->
        </li>
        <li>
          <router-link tabindex="1" :to="showFaq()" class="link">
            {{ $t("Help Center") }}
          </router-link>
          <!-- <a href="#" rel="kee">Help/FAQs</a> -->
        </li>
    </ul>
</template>
<script>
import { eventBus } from "@/eventBus";
import { mapGetters, mapMutations } from "vuex";
import { _providerId, _projectName } from "@/provider-config.js";
import Utility from "@/mixins/Utility.js";
export default {
    data() {
        return {            
            isActivePrivacy: false,
            isActiveTerms: false,
            isActiveAboutUs: false,
            isActiveFaq: false,
            localDisplayLang: null,
        };
    },
    
    methods: {
        ...mapMutations(["setRtl"]),
        showAbout() {
            let currentLanguage = this.getCurrentLanguageUrlBase();

            if (!this.isActiveAboutUs) {
                return '#';
            }
            return ({ name: 'about-switch', params: { lang: currentLanguage } });;
        // this.$router.push({name: 'about-switch', params: {lang: currentLanguage}});
        },
        showFaq() {
            let currentLanguage = this.getCurrentLanguageUrlBase();

            if (!this.isActiveFaq) {
                return "#";
            }
            return ({ name: 'faq-switch', params: { lang: currentLanguage } });
        },
        showTermsOfUse() {
            let currentLanguage = this.getCurrentLanguageUrlBase();

            if (!this.isActiveTerms) {
                return '#';
            }
            return ({ name: 'termsofuse', params: { lang: currentLanguage } });

        },
        showPrivacyPolicy() {
            let currentLanguage = this.getCurrentLanguageUrlBase();

            if (!this.isActivePrivacy) {
                return '#';
            }

            return ({ name: 'privacypolicy', params: { lang: currentLanguage } });

        },
    },

    mounted() {
        if (localStorage.getItem("setDisplayLanguageCode")) {
        this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
        }
        this.isActivePrivacy = window.PROVIDER_SETUP_CONFIG.isPrivacyEnbaled;
        this.isActiveTerms = window.PROVIDER_SETUP_CONFIG.isTermsEnabled;
        this.isActiveAboutUs = window.PROVIDER_SETUP_CONFIG.isShowAboutEnabled;
        this.isActiveFaq = window.PROVIDER_SETUP_CONFIG.isShowFaqEnabled;
    },
    
    computed: {
        ...mapGetters(["appConfig", "getRtl"])
    },
    
    mixins: [Utility],

}
</script>
<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

.item_list {
    display: flex;
    font-size: 0.6rem;
    gap: 0.5rem;
    align-items: center;
    min-height: 1.5rem;
    li{
        color: #8197a4;
        display: inline-block;
        padding: 4px;
        white-space: normal;
        .link{
            color: $clr-text;
            text-decoration: none;
            font-size: 0.8rem;
        }        
        &:hover{
            .link{
                color: #FF0000;
                text-decoration: underline;
            }
        }
    }
}
@media screen and (max-width: 600px) {
 .item_list li .link{
    font-size: 0.58rem;
 
 }
}
</style>